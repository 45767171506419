import { Col, Row, Tabs, Typography } from 'antd'
import Profile from './profile'
import AdminsSetting from './adminsSetting'
import SystemEP from './SystemEP'
import Social from './social'

const items = [
  { label: 'Profile', key: 'PROFILE', children: <Profile /> },
  { label: 'Social Link', key: 'SOCIAL', children: <Social /> },
  { label: 'Admins', key: 'ADMINS', children: <AdminsSetting /> },
  { label: 'System EP', key: 'SYSTEM_EP', children: <SystemEP /> },
]

export const CommunitySetting = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Setting
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Tabs items={items} />
      </Col>
    </Row>
  )
}
