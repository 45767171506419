import { useState } from 'react'
import { Button, Col, Input, Row, Table, Typography } from 'antd'
import { DateTime } from 'luxon'

import SpaceBetween from 'components/systems/spaceBetween'
import CustomPagination from 'components/systems/pagination'
import useHandle from 'hooks/useHandle'

import { DEFAULT_PAGE } from 'constant'
import { EP_LEADERBOARD_COLUMN } from './EPLeaderboardColumn'
import { useEnergyPoints } from 'hooks/energy-point/useEnergyPoints'
import { EnergyPointService } from 'services/bountySystem/energy-point'

const { Search } = Input

const EPLeaderboard = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [isExporting, setExporting] = useState(false)

  const { data, total, isLoading } = useEnergyPoints({
    page,
    search,
  })

  const onExport = useHandle(async () => {
    const timestamp = DateTime.now().toFormat('yyyy-MM-dd-HH-mm-ss')
    const filename = `ep_leaderboard-${timestamp}.csv`
    const blob = await EnergyPointService.exportLeaderboard()
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()
  }, setExporting)

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          EP Leaderboard
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col flex="auto">
                <Search
                  placeholder="Search Item"
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ width: 400 }}
                />
              </Col>
              <Col>
                <Button ghost loading={isExporting} onClick={onExport}>
                  Export
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              className="table-border-collapse"
              columns={EP_LEADERBOARD_COLUMN}
              dataSource={data}
              rowKey={({ _id }) => _id}
              pagination={false}
              loading={isLoading}
            />
          </Col>
          <Col span={24}>
            <SpaceBetween
              floatContent={
                <CustomPagination
                  page={page}
                  total={total}
                  onChange={setPage}
                />
              }
            >
              <Typography.Text type="success">Total: {total}</Typography.Text>
            </SpaceBetween>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default EPLeaderboard
