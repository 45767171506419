import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'
import { CommunityService } from 'services/bountySystem/community/community'

import { Acceler8SubscriptionWithGameName } from 'types/acceler8/acceler8-subscription.type'

export const useAcceler8SubscriptionActive = () => {
  return useQuery({
    queryKey: ['acceler8-subscription-active'],
    queryFn: (): Promise<Acceler8SubscriptionWithGameName[]> =>
      Acceler8Service.getSubscriptionActive().then(
        async (acceler8Subscriptions) => {
          const gamesFromSpace3 = await CommunityService.find({
            communityIds: acceler8Subscriptions.map((e) => e.communityId),
          })

          return acceler8Subscriptions.map((e) => {
            const game = gamesFromSpace3?.data?.find(
              (game) => game._id === e.communityId,
            )

            return {
              ...e,
              gameName: game?.title,
            } as Acceler8SubscriptionWithGameName
          })
        },
      ),
  })
}
