import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AuthService } from 'services/bountySystem/auth'
import { getAccessToken } from 'services/utils'
import { UserService } from 'services/ancient8/user'
import {
  acceler8Api,
  acceler8TaskApi,
  affiliateSystemApi,
  ancient8Api,
  bountySystemApi,
  marketplaceApi,
  coreAdminApi,
} from 'services/base-axios'

/**
 * Interface & Utility
 */

export type UserProfile = {
  _id: string
  accessedSites: any
  avatar: string
  isEmailVerified: boolean
  isEnabled: boolean
  lastLoginTime: Date
  roles: string[]
  userName?: string
  email?: string
}

export type UserState = {
  userProfile: UserProfile
  accessToken: string
}

/**
 * Store constructor
 */

const NAME = 'user'
export const initialState: UserState = {
  accessToken: '',
  userProfile: {} as any,
}

export const login = createAsyncThunk(`${NAME}/login`, async (code: string) => {
  const { data } = await AuthService.login(code)
  updateAxiosAuth(data.accessToken)
  localStorage.setItem('accessToken', data.accessToken)

  const user = await UserService.fetchUserProfile()

  return {
    accessToken: data.accessToken,
    userProfile: { ...user },
  }
})

export const reLogin = createAsyncThunk(`${NAME}/reLogin`, async () => {
  const accessToken = getAccessToken()
  if (accessToken) {
    const user = await UserService.fetchUserProfile()
    return { accessToken, userProfile: { ...user } }
  }
  return { accessToken }
})

export const logout = createAsyncThunk(`${NAME}/logout`, async () => {
  localStorage.removeItem('accessToken')
  return { ...initialState }
})

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder
      .addCase(
        login.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        reLogin.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        logout.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      ),
})

const updateAxiosAuth = (accessToken?: string) => {
  const authorization = accessToken ? `Bearer ${accessToken}` : null

  const axiosInstances = [
    ancient8Api,
    acceler8Api,
    acceler8TaskApi,
    affiliateSystemApi,
    bountySystemApi,
    marketplaceApi,
    coreAdminApi,
  ]

  axiosInstances.forEach(
    (instance) => (instance.defaults.headers['Authorization'] = authorization),
  )
}

export default slice.reducer
