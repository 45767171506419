import { useQuery } from 'react-query'

import { useUserProfile } from 'hooks/user/useUserProfile'

import { userService } from 'services/user'

export const useUser = () => {
  const profile = useUserProfile()

  const data = useQuery(
    `user:data:${profile._id}`,
    () => userService.getUser(),
    {
      staleTime: Infinity,
      enabled: !!profile._id,
    },
  )

  return data
}

export const useAdmins = () => {
  const data = useQuery('user:admins', () => userService.getAdmins(), {
    staleTime: 100000000,
  })
  return data
}
