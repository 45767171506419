import { coreAdminApi, coreApi } from 'services/base-axios'

import { IUser } from 'types/user.type'

class User {
  private controller = '/user'

  async getUser() {
    const { data } = await coreApi.get<IUser>(this.controller)
    return data
  }

  async getAdmins() {
    const { data } = await coreAdminApi.get<IUser[]>(
      `${this.controller}/admins`,
    )
    return data
  }
}

export const userService = new User()
