import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { ChequeService } from 'services/bountySystem/cheque'

import { FilterProps, GetChequesDto } from 'types/cheque.type'

export const useCheques = (query: GetChequesDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_CHEQUES', query],
    queryFn: () => ChequeService.getAll(query),
    enabled: !!query.badgeId,
  })

  return {
    ...rest,
    data: data?.data || [],
    total: data?.total || 0,
  }
}

export const useCountCheque = ({
  filter,
}: {
  filter: Partial<FilterProps>
}) => {
  const [total, setTotal] = useState(0)

  const fetchTotal = useCallback(async () => {
    try {
      const total = await ChequeService.count({ filter })
      setTotal(total)
    } catch (error) {}
  }, [filter])

  useEffect(() => {
    fetchTotal()
  }, [fetchTotal])

  return { total }
}
