import { coreApi as api } from 'services/base-axios'

import { CommunityProfileSectionMap } from 'types/community-profile'
import { Data } from 'types'
import { ICommunityProfile } from './type'

class CommunityProfileAdmin {
  private controller = 'admin/community-profile'

  async createCommunitySection(
    payload: CommunityProfileSectionMap,
  ): Promise<ICommunityProfile> {
    const { data }: { data: ICommunityProfile } = await api.post(
      this.controller + '/section',
      payload,
    )
    return data
  }

  async updateCommunitySection(
    payload: Data<CommunityProfileSectionMap>,
  ): Promise<ICommunityProfile> {
    const { data } = await api.patch(
      this.controller + '/section/' + payload?._id,
      payload,
    )
    return data
  }

  async removeCommunitySection(sectionId: string) {
    await api.delete(this.controller + '/section/' + sectionId)
  }
}

export const CommunityProfileAdminService = new CommunityProfileAdmin()
