import moment from 'moment'

import { Col, Row, Table, Typography } from 'antd'
import AddNew from './addNew'
import Action from './action'
import Loading from 'components/systems/loading'

import { useAdmins } from 'hooks/community/useUser'
import { useUserProfile } from 'hooks/user/useUserProfile'
import { shortenAddress } from 'helper'

import { DATE_FORMAT } from 'constant'
import { ROLE_LABEL } from 'constant/user'

import { IUser, Role } from 'types/user.type'

const columns = [
  { title: 'UID', dataIndex: 'uid', key: 'uid' },
  {
    title: 'Role',
    dataIndex: 'role',
    render: (role: Role) => (
      <Typography.Text>{ROLE_LABEL[role]}</Typography.Text>
    ),
  },
  {
    title: 'Create At',
    dataIndex: 'createdAt',
    render: (createdAt: string) => (
      <Typography.Text>
        {moment.utc(createdAt).format(DATE_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'uid',
    render: (uid: string, { role, _id }: IUser) => (
      <Action uid={uid} role={role} id={_id} />
    ),
  },
]

const AdminsSetting = () => {
  const { data } = useAdmins()
  const { _id } = useUserProfile()

  const owner = data?.find(({ uid }) => uid === _id)

  if (!data || !owner) return <Loading loading />
  return (
    <Row gutter={[24, 24]} style={{ marginTop: 32 }}>
      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">
              {shortenAddress(_id)} | {ROLE_LABEL[owner.role]}
            </Typography.Text>
          </Col>
          <Col>
            <AddNew />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table dataSource={data} columns={columns} rowKey={({ _id }) => _id} />
      </Col>
    </Row>
  )
}

export default AdminsSetting
