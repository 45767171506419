import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'
import { CommunityService } from 'services/bountySystem/community/community'

import {
  Acceler8SortOptions,
  Acceler8SubscriptionWithGameName,
  FindSubscriptionOverallDto,
} from 'types/acceler8/acceler8-subscription.type'
import { DataList } from 'types'

export const DEFAULT_FILTER_OVERALL_DATA = {
  sortOption: Acceler8SortOptions.RecentlyActivated,
  filterOptions: {
    packageIds: [],
    statuses: [],
  },
  searchValue: '',
}

export const useAcceler8SubscriptionOverall = (
  dto?: FindSubscriptionOverallDto,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_ACCELER8_SUBSCRIPTION_OVERALL', dto],
    queryFn: (): Promise<DataList<Acceler8SubscriptionWithGameName[]>> =>
      Acceler8Service.findSubscriptionOverall(dto).then(
        async (acceler8Subscriptions) => {
          const ids = acceler8Subscriptions.data.map((e: any) => e.communityId)

          const gamesFromSpace3 = await CommunityService.find({
            communityIds: ids,
          })

          const result = acceler8Subscriptions.data.map((e: any) => {
            const game = gamesFromSpace3?.data?.find(
              (game) => game._id === e.communityId,
            )

            return {
              ...e,
              gameName: game?.title,
            }
          })

          return {
            ...acceler8Subscriptions,
            data: result,
          }
        },
      ),
  })

  return {
    ...rest,
    data: data?.data ?? [],
    total: data?.total ?? 0,
  }
}
