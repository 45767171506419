import { Typography } from 'antd'

export const COLUMNS = [
  {
    title: 'User ID',
    dataIndex: 'uid',
    render: (uid: string) => (
      <Typography.Text style={{ color: '#B2BFCB' }}>{uid}</Typography.Text>
    ),
  },
]
