import { coreApi as api, coreAdminApi } from 'services/base-axios'

import { FindCommunityDto } from 'services/bountySystem/community/type'
import { DataList } from 'types'
import {
  CreateCommunityDto,
  ICommunity,
  UpdateCommunity,
} from 'types/community.type'

export class Community {
  private controller = '/community'

  async find(params: FindCommunityDto) {
    const { data } = await api.get<DataList<ICommunity[]>>(
      this.controller + '/list',
      { params },
    )
    return data
  }

  async findById(communityId: string) {
    const { data } = await api.get<ICommunity>(
      `${this.controller}/${communityId}`,
    )
    return data
  }

  async createCommunity(data: CreateCommunityDto): Promise<ICommunity> {
    return coreAdminApi.post(this.controller, data).then((data) => data.data)
  }

  async editCommunity(
    communityId: string,
    data: UpdateCommunity,
  ): Promise<ICommunity> {
    return coreAdminApi
      .patch(`${this.controller}/${communityId}`, data)
      .then((data) => data.data)
  }
}

export const CommunityService = new Community()
