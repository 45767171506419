import { Card, Col, Row, Typography } from 'antd'

import SpaceVertical from 'components/systems/spaceVertical'

import { useCountCheque } from 'hooks/cheque/useCheques'

const DIVIDER = { borderRight: 'solid 2px #1B2526' }

type HeroProps = {
  badgeId: string
}

const Hero = ({ badgeId }: HeroProps) => {
  const { total: totalHolders } = useCountCheque({ filter: { badgeId } })
  const { total: totalUsed } = useCountCheque({
    filter: { badgeId, isUsed: true },
  })

  return (
    <Card
      bordered={false}
      style={{ background: '#0F191B' }}
      bodyStyle={{ padding: '16px 24px' }}
    >
      <Row gutter={[12, 12]} style={{ textAlign: 'center' }}>
        {/* Total badge */}
        <Col xs={24} sm={12} md={12} xl={6} style={DIVIDER}>
          <SpaceVertical align="center">
            <Typography.Text type="secondary">
              Total badges holders
            </Typography.Text>
            <Typography.Title type="success" level={3}>
              {totalHolders}
            </Typography.Title>
          </SpaceVertical>
        </Col>
        {/* Total used */}
        <Col xs={24} sm={12} md={12} xl={6} style={DIVIDER}>
          <SpaceVertical align="center">
            <Typography.Text type="secondary">Used</Typography.Text>
            <Typography.Title type="success" level={3}>
              {totalUsed}
            </Typography.Title>
          </SpaceVertical>
        </Col>
      </Row>
    </Card>
  )
}

export default Hero
