import { useParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'

import Hero from './hero'
import OffChain from './offChain'

import { useBadgeData } from 'hooks/badges/useBadges'

const BadgeDetails = () => {
  const badgeId = useParams().itemId || ''
  const { badgeData } = useBadgeData(badgeId)

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {badgeData.title}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Hero badgeId={badgeId} />
      </Col>
      <Col span={24}>
        <OffChain />
      </Col>
    </Row>
  )
}

export default BadgeDetails
