import { useQuery } from 'react-query'

import { EnergyPointService } from 'services/bountySystem/energy-point'

import { GetEnergyPointDto } from 'types/energy-point.type'

export const useEnergyPoints = (dto: GetEnergyPointDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_ENERGY_POINTS', dto],
    queryFn: () => EnergyPointService.getAll(dto),
  })

  return {
    ...rest,
    data: data?.data,
    total: data?.total ?? 0,
  }
}
