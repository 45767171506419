export enum AuthType {
  EVMChain = 'AUTH_TYPE::EVM_CHAIN',
  Solana = 'AUTH_TYPE::SOLANA',
  OAuth = 'AUTH_TYPE::OAUTH',
  Password = 'AUTH_TYPE::PASSWORD',
  Discord = 'AUTH_TYPE::DISCORD',
  Twitter = 'AUTH_TYPE::TWITTER',
  Facebook = 'AUTH_TYPE::FACEBOOK',
  Twitch = 'AUTH_TYPE::TWITCH',
  Google = 'AUTH_TYPE::GOOGLE',
  EmailOTP = 'AUTH_TYPE::EMAIL_OTP',
  Ronin = 'AUTH_TYPE::RONIN',
  //AWARE: For show logoChain
  Default = 'AUTH_TYPE::DEFAULT',
}

export enum SocialIdName {
  WalletAddress = 'walletAddress',
  DiscordUserId = 'discordUserId',
  TwitterUserId = 'twitterUserId',
  FacebookUserId = 'facebookUserId',
  TwitchUserId = 'twitchUserId',
  GoogleUserId = 'googleUserId',
  EmailUserId = 'emailUserId',
}

export enum SocialUserName {
  DiscordUsername = 'discordUserName',
  TwitterUsername = 'twitterUserName',
  FacebookUsername = 'facebookUserName',
  TwitchUsername = 'twitchUserName',
  GoogleUsername = 'googleEmail',
  EmailUsername = 'emailUserName',
}

export type Credential = Record<SocialIdName, string> &
  Record<SocialUserName, string>

export interface AuthEntity {
  _id: string
  userId: string
  type: AuthType
  credential?: Credential
  isPrimary: boolean
}

export interface IA8IdProfile {
  _id: string
  userAuths: AuthEntity[]
  avatar: string
  username: string | null
  email: string | null
}

export enum Role {
  User = 'USER_ROLE::USER',
  Admin = 'USER_ROLE::ADMIN',
  Operator = 'USER_ROLE::OPERATOR',
}

export interface IUser {
  _id: string
  uid: string
  role: Role
}
