import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Row, Table, Typography } from 'antd'

import { WrapLoading } from 'components/systems/loading'
import SearchBar from 'components/systems/searchBar'
import CustomPagination from 'components/systems/pagination'
import SpaceBetween from 'components/systems/spaceBetween'

import { useCheques } from 'hooks/cheque/useCheques'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'
import { COLUMNS } from './column'

const OffChain = () => {
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [search, setSearch] = useState('')

  const badgeId = useParams().itemId || ''
  const {
    data: cheques,
    total,
    isLoading,
  } = useCheques({
    badgeId,
    search,
    limit: DEFAULT_PAGE_SIZE,
    offset: (page - 1) * DEFAULT_PAGE_SIZE,
  })

  useEffect(() => {
    setPage(DEFAULT_PAGE)
  }, [search])

  return (
    <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
      <Col span={24}>
        <SearchBar
          setSearch={setSearch}
          placeholder="Search UID"
          style={{ width: 244 }}
        />
      </Col>
      <Col span={24}>
        <WrapLoading loading={isLoading}>
          <Table
            className="table-border-collapse"
            columns={COLUMNS}
            dataSource={cheques}
            pagination={false}
            rowKey="_id"
            rowSelection={{ type: 'checkbox' }}
          />
        </WrapLoading>
      </Col>
      <Col span={24}>
        <SpaceBetween
          floatContent={
            <CustomPagination
              total={total}
              page={page}
              onChange={setPage}
              pageSize={DEFAULT_PAGE_SIZE}
            />
          }
        >
          <Typography.Text type="success">Total: {total}</Typography.Text>
        </SpaceBetween>
      </Col>
    </Row>
  )
}

export default OffChain
