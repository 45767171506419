import { bountySystemApi as api } from 'services/base-axios'

import { DataList } from 'types'
import { ChequeData, FilterProps, GetChequesDto } from 'types/cheque.type'

const CONTROLLER = '/cheque'
const ADMIN_CONTROLLER = '/admin/cheque'

class Cheque {
  async getAll(params: GetChequesDto) {
    return api
      .get<DataList<ChequeData[]>>(ADMIN_CONTROLLER, { params })
      .then((res) => res.data)
  }

  async count({ filter }: { filter: Partial<FilterProps> }) {
    const params = { ...filter }
    return api
      .get(`${CONTROLLER}/count`, {
        params,
      })
      .then((res) => res.data)
  }
}

export const ChequeService = new Cheque()
