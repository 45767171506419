import { coreAdminApi as api } from 'services/base-axios'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'
import { DataList } from 'types'
import { EnergyPointData, GetEnergyPointDto } from 'types/energy-point.type'

const CONTROLLER = '/energy-point'

class EnergyPoint {
  async getAll({
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    search = '',
  }: GetEnergyPointDto) {
    const res = await api.get<DataList<EnergyPointData[]>>(`${CONTROLLER}`, {
      params: { offset: (page - 1) * pageSize, limit: pageSize, search },
    })
    return res.data
  }

  async exportLeaderboard(): Promise<Blob> {
    const { data } = await api.get(`${CONTROLLER}/export`, {
      responseType: 'blob',
    })
    return data
  }
}

export const EnergyPointService = new EnergyPoint()
