import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDebounce } from 'react-use'

import { Dropdown, Input, MenuProps, Space, Typography, Button } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'
import { useCommunities } from 'hooks/community/useCommunities'

import './index.less'

export const CommunityToggle: React.FC = () => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  useDebounce(() => setDebouncedSearch(search), 500, [search])

  const [currentCommunity, setCurrentCommunity] = useCurrentCommunity()
  const { communities, isLoading } = useCommunities({ search: debouncedSearch })

  useEffect(() => {
    if (!currentCommunity && communities.length > 0) {
      setCurrentCommunity(communities[0])
    }
  }, [communities, currentCommunity, setCurrentCommunity])

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
    },
    [],
  )

  const handleMenuClick: MenuProps['onClick'] = useCallback(
    ({ key }: { key: string }) => {
      const selected = communities.find((c) => c._id === key)
      if (selected) {
        setCurrentCommunity(selected)
      }
    },
    [communities, setCurrentCommunity],
  )

  const menuItems: MenuProps['items'] = useMemo(
    () => communities.map((c) => ({ key: c._id, label: c.title })),
    [communities],
  )

  return (
    <Dropdown
      className="community-dropdown"
      trigger={['click']}
      menu={{
        selectable: true,
        items: menuItems,
        onClick: handleMenuClick,
      }}
      dropdownRender={(menu) => (
        <div className="community-dropdown-content">
          <Input
            placeholder="Search communities..."
            value={search}
            onChange={onSearchChange}
            style={{ marginBottom: 8 }}
          />

          {isLoading ? (
            <Typography.Text
              type="secondary"
              style={{ display: 'block', textAlign: 'center' }}
            >
              Loading...
            </Typography.Text>
          ) : communities.length === 0 ? (
            <Typography.Text
              type="secondary"
              style={{ display: 'block', textAlign: 'center' }}
            >
              No communities found
            </Typography.Text>
          ) : (
            menu
          )}
        </div>
      )}
    >
      <Button type="text" className="community-toggle-btn">
        <Space size={12}>
          <Typography.Text className="community-toggle-text">
            {currentCommunity?.title || 'Select community'}
          </Typography.Text>
          <IonIcon
            className="community-toggle-icon"
            name="chevron-down-outline"
          />
        </Space>
      </Button>
    </Dropdown>
  )
}
