import { useEffect, useState } from 'react'
import moment from 'moment'

import {
  Col,
  Row,
  Typography,
  Button,
  InputNumber,
  Space,
  DatePicker,
} from 'antd'

import Loading from 'components/systems/loading'

import { useCommunity } from 'hooks/community/useCommunity'

import { coreAdminApi as api } from 'services/base-axios'
import { notifyError, notifySuccess } from 'helper'

import { DATE_FORMAT } from 'constant'

const SystemEP = () => {
  const [epExpired, setEPExpired] = useState(0)
  const [epMerged, setEPMerged] = useState(0)
  const [timeMark, setTimeMark] = useState<moment.Moment | null>(null)
  const [loading, setLoading] = useState(false)
  const { data } = useCommunity()

  useEffect(() => {
    if (!data?.data) return
    setEPExpired(data.data.ccpLifeMonths)
    setEPMerged(data.data.ccpMergeMonths)
    setTimeMark(moment.utc(data.data.ccpTimeMark))
  }, [data?.data])

  const handleSave = async () => {
    try {
      setLoading(true)
      if (!timeMark) throw new Error('Invalid time mark')

      await api.patch('/community', {
        ccpTimeMark: timeMark.toISOString(),
        ccpLifeMonths: epExpired,
        ccpMergeMonths: epMerged,
      })
      notifySuccess('Updated System EP')
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  if (!data) return <Loading loading />

  return (
    <Row gutter={[24, 24]} style={{ paddingTop: 20, maxWidth: 486 }}>
      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">
            {`Time Mark (${DATE_FORMAT})`}
          </Typography.Text>
          <DatePicker
            value={timeMark}
            style={{ width: '100%' }}
            onChange={(e) => setTimeMark(e)}
            format={DATE_FORMAT}
          />
        </Space>
      </Col>

      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">Expired Months</Typography.Text>
          <InputNumber
            value={epExpired}
            placeholder="Enter number of expired months..."
            onChange={(e) => setEPExpired(Number(e))}
          />
        </Space>
      </Col>

      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">Merged Months</Typography.Text>
          <InputNumber
            value={epMerged}
            placeholder="Enter number of merged months..."
            onChange={(e) => setEPMerged(Number(e))}
          />
        </Space>
      </Col>

      <Col span={8}>
        <Button type="primary" onClick={handleSave} loading={loading}>
          Save
        </Button>
      </Col>
    </Row>
  )
}

export default SystemEP
