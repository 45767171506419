import { ReactNode } from 'react'

export type ValueOf<T> = T[keyof T]
export const TIME_FORMAT = 'YYYY/MM/DD HH:mm'
export const EXPORT_TIME_FORMAT = 'YYYY-MM-DD HH-mm-ss'
export const DATE_FORMAT = 'YYYY/MM/DD'

export const DEFAULT_PAGE = 1
export const DEFAULT_PAGE_SIZE = 24
export const MULTIPLIER_PAGE_SIZE = 10
export const DEFAULT_COMMUNITY_LIMIT = 50

export enum ChainID {
  Solana = 'CHAIN_ID:SOLANA',
  BSC = 'CHAIN_ID:BSC',
  Ethereum = 'CHAIN_ID:ETHEREUM',
  A8 = 'CHAIN_ID:A8',
  Optimism = 'CHAIN_ID:OPTIMISM',
  Sepolia = 'CHAIN_ID:SEPOLIA',
  Polygon = 'CHAIN_ID:POLYGON',
  Arbitrum = 'CHAIN_ID:ARBITRUM',
  Base = 'CHAIN_ID:BASE',
  Ronin = 'CHAIN_ID:RONIN',
  Kroma = 'CHAIN_ID:KROMA',
  Mint = 'CHAIN_ID:MINT',
  Etherlink = 'CHAIN_ID:ETHERLINK',
}

export const ChainLabel: Record<ChainID, string> = {
  [ChainID.A8]: 'A8',
  [ChainID.Solana]: 'Solana',
  [ChainID.BSC]: 'BSC',
  [ChainID.Ethereum]: 'ETH',
  [ChainID.Sepolia]: 'Sepolia',
  [ChainID.Optimism]: 'OP',
  [ChainID.Polygon]: 'POLYGON',
  [ChainID.Arbitrum]: 'Arbitrum',
  [ChainID.Base]: 'Base',
  [ChainID.Ronin]: 'Ronin',
  [ChainID.Kroma]: 'Kroma',
  [ChainID.Mint]: 'Mint',
  [ChainID.Etherlink]: 'Etherlink',
}

export const AuthType: Record<ChainID, string> = {
  [ChainID.A8]: 'AUTH_TYPE::EVM_CHAIN',
  [ChainID.BSC]: 'AUTH_TYPE::EVM_CHAIN',
  [ChainID.Solana]: 'AUTH_TYPE::SOLANA',
  [ChainID.Ethereum]: 'AUTH_TYPE::EVM_CHAIN',
  [ChainID.Sepolia]: 'AUTH_TYPE::EVM_CHAIN',
  [ChainID.Optimism]: 'AUTH_TYPE:EVM_CHAIN',
  [ChainID.Polygon]: 'AUTH_TYPE:EVM_CHAIN',
  [ChainID.Arbitrum]: 'AUTH_TYPE:EVM_CHAIN',
  [ChainID.Base]: 'AUTH_TYPE:EVM_CHAIN',
  [ChainID.Mint]: 'AUTH_TYPE:EVM_CHAIN',
  [ChainID.Ronin]: 'AUTH_TYPE:RONIN',
  [ChainID.Kroma]: 'AUTH_TYPE:EVM_CHAIN',
  [ChainID.Etherlink]: 'AUTH_TYPE:EVM_CHAIN',
}

export enum ProfileItemType {
  MyItems = 'My Items',
  OrderedItems = 'Ordered Items',
}

export enum FormNoticeType {
  UploadMaxSize = 'Upload Picture (Max 20MB)',
}

export enum FormActions {
  Create = 'ACTION:CREATE',
  Update = 'ACTION:UPDATE',
}

export interface IOptionElement {
  label: ReactNode
  value: string | number
  disabled?: boolean
}

export class OptionElement implements IOptionElement {
  label: ReactNode
  value: string | number
  disabled: boolean

  constructor(label: ReactNode, value: string | number, disabled = false) {
    this.label = label
    this.value = value
    this.disabled = disabled
  }
}

export enum TypeFiles {
  Csv = 'csv',
}

export const TITLE_MIN_LEN = 5
export const TITLE_MAX_LEN = 500
export const DESC_MIN_LEN = 5
export const DESC_MAX_LEN = 5000

export const ACCELER8_DATE_TIME_FORMAT = 'MM/DD HH:mm'

export const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000

export enum RecurrentTime {
  OneTime = 0,
  Daily = ONE_DAY_IN_MILLIS,
  Weekly = ONE_DAY_IN_MILLIS * 7,
  Monthly = ONE_DAY_IN_MILLIS * 30,
}

export const RecurrentTimeLabel: Record<RecurrentTime, string> = {
  [RecurrentTime.OneTime]: 'One Time',
  [RecurrentTime.Daily]: 'Daily',
  [RecurrentTime.Weekly]: 'Weekly',
  [RecurrentTime.Monthly]: 'Monthly',
}
