import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'

import { Button, Checkbox, Col, Form, Input, Popconfirm, Row } from 'antd'

import RequiredLabel from 'components/systems/requiredSymbol'
import FormItemOfficialLink from 'view/community/management/profile/formCommunitySection/formItemOfficialLink'
import FormItemOverview from 'view/community/management/profile/formCommunitySection/formItemOverview'
import FormItemSystemFeatured from 'view/community/management/profile/formCommunitySection/formItemSystemFeatured'
import FormItemFeaturedContent from 'view/community/management/profile/formCommunitySection/formItemFeaturedContent'
import FormItemTeamMembers from 'view/community/management/profile/formCommunitySection/formItemTeamMembers'
import FormItemCompany from 'view/community/management/profile/formCommunitySection/formItemCompany'

import { useNewCommunitySection } from 'view/community/management/profile'
import { useCommunityProfile } from 'hooks/community/useCommunityProfile'

import { CommunityProfileAdminService } from 'services/bountySystem/community/community-profile-admin'

import { notifyError, notifySuccess } from 'helper'
import { removeEmptyNested } from 'helper/common'

import { COMMUNITY_PROFILE_DEFAULT_SECTION } from 'constant/community-profile'

import {
  CommunityProfileSection,
  CommunitySectionType,
} from 'types/community-profile'
import { Data } from 'types'

type FormSectionProviderProps = {
  type?: CommunitySectionType
  sectionData?: Data<CommunityProfileSection<CommunitySectionType>>
}

function FormSectionProvider({ type, sectionData }: FormSectionProviderProps) {
  const [form] = Form.useForm()
  const [sectionType, setSectionType] = useState<
    CommunitySectionType | undefined
  >(sectionData && sectionData?.type)
  const [, setCreate] = useNewCommunitySection()

  const communityId = useParams().communityId || ''
  const isEdit = !!sectionData?._id

  const { refetch: fetchCommunityProfileData } =
    useCommunityProfile(communityId)

  const { mutate: createCommunitySection } = useMutation({
    mutationKey: ['create-community-section'],
    mutationFn: (payload: any) => {
      return CommunityProfileAdminService.createCommunitySection(payload)
    },
    onSuccess: () => {
      notifySuccess('Create Community Section')
      fetchCommunityProfileData()
      setCreate({ type: undefined })
    },
    onError: (error) => {
      notifyError(error)
    },
  })

  const { mutate: removeCommunitySection } = useMutation({
    mutationKey: ['remove-community-section'],
    mutationFn: (sectionId: string) => {
      return CommunityProfileAdminService.removeCommunitySection(sectionId)
    },
    onSuccess: () => {
      notifySuccess('Remove Community Section')
      fetchCommunityProfileData()
    },
    onError: (error) => {
      notifyError(error)
    },
  })

  const { mutate: updateCommunitySection } = useMutation({
    mutationKey: ['update-community-section'],
    mutationFn: (payload: any) => {
      return CommunityProfileAdminService.updateCommunitySection(payload)
    },
    onSuccess: () => {
      notifySuccess('Update Community Section')
      fetchCommunityProfileData()
    },
    onError: (error) => {
      notifyError(error)
    },
  })

  const handleRemoveSection = () => {
    removeCommunitySection(sectionData?._id ?? '')
  }

  const handleSubmit = useCallback(
    (values: CommunityProfileSection<CommunitySectionType>) => {
      const payload = removeEmptyNested({
        ...values,
        type: sectionType,
      })

      if (isEdit) {
        return updateCommunitySection({ ...payload, _id: sectionData._id })
      }

      return createCommunitySection(payload)
    },
    [
      isEdit,
      sectionData?._id,
      sectionType,
      updateCommunitySection,
      createCommunitySection,
    ],
  )

  useEffect(() => {
    !isEdit &&
      form.setFieldsValue({
        communityId,
        ...COMMUNITY_PROFILE_DEFAULT_SECTION,
      })
  }, [isEdit, communityId, form])

  useEffect(() => {
    !isEdit && setSectionType(type ?? CommunitySectionType.Overview)
  }, [isEdit, type])

  useEffect(() => {
    sectionData && form.setFieldsValue(sectionData)
  }, [sectionData, form])

  return (
    <>
      <Form
        form={form}
        name="form-section"
        onFinish={handleSubmit}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        labelAlign="left"
        requiredMark={false}
        colon={false}
      >
        {/*Section Overview*/}
        {sectionType === CommunitySectionType.Overview && (
          <FormItemOverview form={form} />
        )}

        {/*Section System Featured*/}
        {sectionType === CommunitySectionType.System && (
          <FormItemSystemFeatured form={form} />
        )}

        {/*Section Featured Content*/}
        {sectionType === CommunitySectionType.Featured && (
          <FormItemFeaturedContent />
        )}

        {/*Section Team Members*/}
        {sectionType === CommunitySectionType.Member && <FormItemTeamMembers />}

        {/*Section Company*/}
        {sectionType === CommunitySectionType.Company && <FormItemCompany />}

        {/*Section Official Link*/}
        {sectionType === CommunitySectionType.OfficialLink && (
          <FormItemOfficialLink />
        )}

        {/*Basic info*/}
        {/*Community ID default hidden*/}
        <Form.Item
          label={<RequiredLabel children="Community ID" />}
          name="communityId"
          hidden
        >
          <Input placeholder="Community ID" />
        </Form.Item>

        <Form.Item
          label={<RequiredLabel children="Enabled" />}
          name="enabled"
          valuePropName="checked"
          required
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          label={<RequiredLabel children="Order index" />}
          name="orderIndex"
        >
          <Input type="number" placeholder="Order index" />
        </Form.Item>

        <Row gutter={8}>
          {isEdit && (
            <Col>
              <Popconfirm
                placement="topLeft"
                title="Are you sure you want to remove this section?"
                onConfirm={handleRemoveSection}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger>
                  Remove
                </Button>
              </Popconfirm>
            </Col>
          )}
          <Col>
            <Button type="primary" htmlType="submit">
              {isEdit ? 'Update' : 'Submit'}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default FormSectionProvider
