import axios from 'axios'

import configs from 'configs'
import { getAccessToken } from 'services/utils'

const token = getAccessToken()

const axiosConfig = {
  headers: {
    Authorization: !token ? '' : `Bearer ${token}`,
  },
  timeout: 30000,
  baseURL: `${configs.api.coreApi}`,
}

export const coreApi = axios.create(axiosConfig)
