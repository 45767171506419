import { Fragment, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useIsLoggedIn } from 'hooks/user/useIsLoggedIn'
import { ChainState, setChains } from 'store/chain.reducer'
import { chainService } from 'services/core/chain'
import { Sort } from 'services/core/chain/type'
import { AppDispatch } from 'store'
import { CHAIN_STATE_DEFAULT } from 'constant/chain'

const DataWatcher = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isLoggedIn = useIsLoggedIn()

  const fetchChains = useCallback(async () => {
    if (isLoggedIn) {
      const chainConfig: ChainState = { ...CHAIN_STATE_DEFAULT }
      const chains = await chainService.getAllChain({ sort: Sort.Latest })
      for (const chain of chains) {
        chainConfig[chain.chainId] = chain
      }
      await dispatch(
        setChains({
          chainConfig,
        }),
      )
    }
  }, [dispatch, isLoggedIn])

  useEffect(() => {
    fetchChains()
  }, [fetchChains])

  return <Fragment />
}

export default DataWatcher
