import { coreApi as api } from 'services/base-axios'

import {
  CommunityProfileSection,
  CommunitySectionType,
  GetAllCommunityProfileDto,
} from 'types/community-profile'
import { Data } from 'types'

class CommunityProfile {
  private controller = '/community-profile'

  async getAllSectionByCommunityId(params: GetAllCommunityProfileDto) {
    const { data } = await api.get<
      Data<CommunityProfileSection<CommunitySectionType>>[]
    >(this.controller + '/section', { params })
    return data
  }
}

export const CommunityProfileService = new CommunityProfile()
