import { useNavigate } from 'react-router-dom'

import { Card, Col, Row, Image, Typography, Divider } from 'antd'

import { ROUTES } from 'constant/routes'
import { BadgeData } from 'types/badge.type'

type CardNftProps = {
  badge: BadgeData
}

const CardNft = ({ badge }: CardNftProps) => {
  const navigate = useNavigate()

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      bordered
      className="card-nft"
      onClick={() => navigate(`${ROUTES.NFT_BADGE.INDEX}/${badge._id}`)}
    >
      <Row>
        <Col span={24}>
          <Image
            preview={false}
            src={badge.thumbnail}
            style={{ aspectRatio: 1 / 1, objectFit: 'cover' }}
          />
        </Col>
        <Col span={24} style={{ padding: 12 }}>
          <Row gutter={[4, 4]}>
            <Col span={24}>
              <Typography.Text type="success">
                {badge.supply} NFT
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Divider style={{ margin: 0 }} />
            </Col>
            <Col span={24}>
              <Typography.Title ellipsis style={{ color: '#B2BFCB' }} level={5}>
                {badge.title}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default CardNft
