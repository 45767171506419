import { useQuery } from 'react-query'

import { coreApi as api } from 'services/base-axios'

import { ICommunity } from 'types/community.type'

export const useCommunity = () => {
  const data = useQuery(
    'community:data',
    () => api.get<ICommunity>('/community'),
    // { staleTime: 100000000 },
  )
  return data
}
